// Imports
  @import 'modules/_fonts';
  @import 'modules/_all';
// END Imports

// HEADER
////////////////////////
header#main-header {
  @media screen and (min-width: $tablet + 1) {
    .dd-logo {
      width: 107px !important;
    }
  }
}
// fim HEADER
////////////////////////

#dd-banner-home {
  height: 52vw;
  max-height: 525px;
  position: relative;
}

.dd-section-box-options {
  background: url(/assets/images/bg-stripe-boxes.png) center center repeat;
  padding: 40px 0;
  text-align: center;

  .dd-box-list li {
    width: 180px;
    height: 180px;
    margin-right: 1%;
    vertical-align: top;
    @include fade;

    @media screen and (max-width: 952px) {
      margin-bottom: 9px;
    }

    @media screen and (max-width: $mobile) {
      display: block;
      margin: 0 auto 10px;
    }

    article {
      height: 100%;
      padding-top: 35px;
    }

    figure {
      text-align: center;
    }

    p {
      font-size: 16px;
      text-align: center;
      line-height: 18px;
      padding-top: 16px;

      @media screen and (max-width: 920px) {
        font-size: 14px;
      }

      @media screen and (max-width: $tablet) {
        font-size: 15px;
      }
    }

    .dd-icon {
      background-image: url(/assets/images/icons-sprite.png);
      background-repeat: no-repeat;
      display: block;
      margin: 0 auto;
    }

    &:nth-child(1){
      border: 2px solid #f67500;

      a {
        color: #f67500;
      }

      .dd-icon {
        background-position: left top;
        width: 52px;
        height: 56px;
      }

      &:hover {
        background: #f67500;

        .dd-icon {
          background-position: -52px top;
        }

        a {
          color: #FFF;
        }
      }
    }

    &:nth-child(2){
      border: 2px solid #102b4e;

      a {
        color: #102b4e;
      }

      .dd-icon {
        background-position: left -63px;
        width: 63px;
        height: 67px;
      }

      &:hover {
        background: #102b4e;

        .dd-icon {
          background-position: -63px -61px;
        }

        a {
          color: #FFF;
        }
      }
    }

    &:nth-child(3){
      border: 2px solid #580900;

      .dd-icon {
        background-position: -2px -142px;
        width: 60px;
        height: 67px;
      }

      a {
        color: #580900;
      }

      &:hover {
        background: #580900;

        .dd-icon {
          background-position: -65px -142px;
        }

        a {
          color: #FFF;
        }
      }
    }

    &:nth-child(4){
      border: 2px solid #3d9b00;

      a {
        color: #3d9b00;
      }

      .dd-icon {
        background-position: 0px -225px;
        width: 70px;
        height: 67px;
      }

      &:hover {
        background: #3d9b00;

        .dd-icon {
          background-position: -81px -225px;
        }

        a {
          color: #FFF;
        }
      }
    }

    &:nth-child(5){
      border: 2px solid #898989;
      margin-right: 0px;

      @media screen and (max-width: $mobile) {
        margin: 0 auto 10px;
      }

      .dd-icon {
        background-position: 0px -312px;
        width: 65px;
        height: 67px;
      }

      a {
        color: #898989;
      }

      &:hover {
        background: #898989;

        .dd-icon {
          background-position: -72px -312px;
        }

        a {
          color: #FFF;
        }
      }
    }
  }
}

.dd-section-local {
  background: url(/assets/images/bg-local.png) top center repeat-y;
  padding-top: 11px;
  padding-bottom: 32px;

  .dd-title {
    font-size: 104px;
    letter-spacing: 1px;
    color: rgba($black, .6);

    @media screen and (max-width: 660px) {
      font-size: 60px;
    }

    @media screen and (max-width: $mobile) {
      font-size: 49px;
    }
  }

  .dd-sub-title {
    line-height: 5px;
    font-size: 41px;
    color: rgba($black, .6);
    margin-bottom: 28px;

    @media screen and (max-width: 660px) {
      font-size: 27px;
    }

    @media screen and (max-width: $mobile) {
      font-size: 21px;
    }
  }

  .dd-link-image {
    width: 100%;
    color: $white;
    text-align: center;
    text-decoration: underline;
    font-size: 15px;
    display: block;
  }

  .dd-box-img {
    .dd-link-image {
      position: absolute;
      top: 31px;
      color: $brown;
      z-index: 9999;

      @media screen and (max-width: $tablet) {
        top: 41px;
      }
    }
  }

  .dd-link-more {
    display: block;
    text-align: right;
    color: $white;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: underline;

    @media screen and (max-width: $tablet) {
      text-align: left;
    }

    &:hover{
      color: #f98100;
    }
  }

  .bx-wrapper {
    max-width: 678px !important;
    width: 80% !important;
    margin: 0 auto;
    margin-top: 28px !important;

    @media screen and (max-width: $tablet) {
      max-width: 500px !important;
    }
  }

  .dd-slider-local {

    li {
      font-size: 0;

      & > * {
        display: inline-block;
      }
    }

    .dd-box-text {
      width: 70%;
      padding-right: 71px;

      @media screen and (max-width: $tablet) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 23px;
      }
    }

    .dd-box-image {
      width: 30%;
      text-align: center;

      @media screen and (max-width: $tablet) {
        width: 100%;
        text-align: center;
      }
    }

    .dd-box-text,
    .dd-image-slide {
      vertical-align: middle;
    }
  }

  .dd-title-slide {
    font-size: 38px;
    color: $white;
    margin-top: 10px;
    margin-bottom: 16px;
    letter-spacing: 1px;
  }

  .dd-text-slider {
    font-size: 13px;
    color: $white;
    text-align: justify;
  }

  .dd-arrow-left {
    left: 43px;

    @media screen and (max-width: $large) {
      left: -4%;
    }
  }

  .dd-arrow-right {
    right: 43px;

    @media screen and (max-width: $large) {
      right: -4%;
    }
  }
}

// MAPA
.dd-c-map {
  margin: 40px 0 0;
}

img[usemap] {
	border: none;
	height: auto;
	max-width: 100%;
	width: auto;
}

.dd-c-map-overmap,
.dd-c-map-overlay {
  position: absolute;
  top: 0;
}

.dd-c-map-overmap {
  z-index: 9990;
}

.dd-map-hidden {
  display: none;
}

.dd-c-map-overlay {
  z-index: 9998;
}
// FIM MAPA

.dd-c-slider-local {
  visibility: hidden;
}

map,
area {
  cursor: pointer;
}
